<template>
  <dialog-edit :value="value" @input="$emit('input')">
    <template v-slot:title>
      {{ data.name }}
      <small v-if="id"> ({{ id }})</small>
    </template>
    <div v-if="!loading" style="text-align: initial" v-html="data.content"></div>
    <a-loader v-if="loading" />
    <template v-if="!loading" v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('input')">Закрыть</v-btn>
    </template>
  </dialog-edit>
</template>

<script>
export default {
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      api: "/7perfect/information",
      data: {},
      loading: false,
    };
  },
  computed: {},
  created() {
    if (this.id) this.updateData(this.id);
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    async updateData() {
      this.loading = true;
      const r = await this.$axios.get(this.api + "/" + this.id);
      this.data = r.data.data;
      this.loading = false;
    },
  },
};
</script>
